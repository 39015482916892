import React from "react";
import { Link } from "gatsby";
import PortableText from '../portableText';
//import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage as Img } from "gatsby-plugin-image/compat"
import { StyledPanel } from './styledCategoryTypeRepeater'

const CategoryTypeRepeater = ({ categoryTypes, mainCategory, twoPerRow }) => {

  const imageColStyles = "imageCol w-0 md:w-1/2";
  let contentColStyles = "contentCol w-full md:w-1/2 py-16 px-8 md:py-32 md:px-16";
  let wrapperClassName = "";
  let panelClassName = "";

  const fullWidthLastPanel = twoPerRow && categoryTypes.length % 2 === 0 ? false : true;


  if (twoPerRow) {
    contentColStyles = "contentCol w-full py-16 px-8 md:py-32 md:px-16"
    wrapperClassName += "flex flex-wrap";
    panelClassName = fullWidthLastPanel ? "twoPerRow fullWidthLastPanel md:w-1/2" : "twoPerRow md:w-1/2";
  }

  return (
    <div className={wrapperClassName}>

      {categoryTypes.map(t => {

        let slug;
        if (t.subPageLink !== null) {
          slug = t.subPageLink.slug.current;
        }

        const altTitle = t.mainImageAlt || '';

        return (
          <StyledPanel className={panelClassName} key={t._key} title={altTitle}>
            <Img
              className="panelBg"
              fluid={{...t.mainImage.asset.fluid}}
              
            /> 

            {!twoPerRow && <div className={imageColStyles}> </div>}
             
             
              <div className={contentColStyles}>
                
                <h2 className="heading text-white text-3xl mb-6">{t.title}</h2>
                <div className="text-white">
                  {t.shortDescription &&
                    <PortableText blocks={t.shortDescription} />
                  }
                </div>
                {slug && <Link className="cta cta-white" to={`${slug}`} >MORE INFO</Link>}
              </div>
          </StyledPanel>
        )
      })}

    </div>
  );
}



export default CategoryTypeRepeater;
