import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PortableText from '../components/portableText';
import CategoryTypeRepeater from '../components/CategoryTypeRepeater/category-type-repeater';
import deepmerge from 'deepmerge';
import SEO from '../components/seo';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const query = graphql`
query EntertainmentPageQuery {
  page: sanityEntertainmentPage {
    mainCategory
    title
    _rawEntertainmentTypes
    entertainmentTypes {
      mainImageAlt
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      subPageLink{
        slug{
					current
        }
      }
    }
    _rawMainContent
    mainImageAlt
    mainImage {
      asset {
        fluid(maxWidth: 1920) {
          ...GatsbySanityImageFluid
        }
      }
    }
    seoFields {
      title
      description
    }
  }
}
`;

const EntertainmentPage = ({ data }) => {
  const bannerImgFluid = data.page.mainImage.asset.fluid;
  const {seoFields} = data.page;
  const {mainImageAlt} = data.page || '';


  // this is a bit hacky, probably some better way to set this up in the schema instead
  const rawTypes = data.page._rawEntertainmentTypes;
  const tidyTypes = data.page.entertainmentTypes;
  const mergedTypes = []
  rawTypes.forEach((e, i) => {
    mergedTypes.push(deepmerge(rawTypes[i], tidyTypes[i]))
  });

  return (
    <Layout bannerAlt={mainImageAlt} showNav={true} showBanner={true} bannerImgFluid={bannerImgFluid} bannerTitle={data.page.title}>
      <SEO
        title={seoFields.title}
        description={seoFields.description}
        bodyAttr={{
          class: "",
        }}
      />
      <main  >
        <div className="py-16 md:py-24">
       
        {data.page._rawMainContent &&
          <div className="container mx-auto text-white">
            <PortableText blocks={data.page._rawMainContent} />
            <a className="cta" href="#contact" onClick={() => scrollTo('#contactForm')}>Get a quote</a>
          </div>
        }
        </div>
        <CategoryTypeRepeater categoryTypes={mergedTypes} mainCategory={data.page.mainCategory}/>

      </main>
    </Layout>
  )
};

export default EntertainmentPage;
