import styled from "styled-components";

export const StyledPanel = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  .gatsby-image-wrapper img[data-main-image]{
     animation: fadeIn 0.25s both;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
    .contentCol::before {
      background-color: #b4914c;
    }
  }

  &.twoPerRow {
    @media (min-width: 640px) {
      &:nth-child(4n + 1) .contentCol::before {
        background-color: #b4914c;
      }

      &:nth-child(4n + 2) .contentCol::before {
        background-color: #000;
      }

      &:nth-child(4n + 3) .contentCol::before {
        background-color: #000;
      }

      &:nth-child(4n + 4) .contentCol::before {
        background-color: #b4914c;
      }
    }
  }

  &.fullWidthLastPanel {
    &:last-child {
      width: 100%;

      .contentCol {
        @media (min-width: 640px) {
          width: 50%;
          margin: 0 auto;

          &:before {
            min-width: 100vw;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  &:hover {

    .contentCol{

      .heading, .text-white{
      text-shadow: 1px 1px 0 rgba(0,0,0,0.5);

      }
    }

    .contentCol:before {
      opacity: 0.5;
      
    }
    .panelBg {
      transform: scale(1.05);
    }

    .heading::before {
      transform: scale(1);
      opacity: 1;
      transform-origin: center left;
    }
  }

  .panelBg {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform 1.5s ease-out;
  }

  .contentCol {
    //padding: 8em 4em;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #000;
      opacity: 0.7;
      z-index: -1;
      transition: opacity 0.5s;
    }
  }

  .imageCol {
  }

  .heading {
    display: inline-block;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 2px solid #fff;
      left: 0;
      opacity: 0;
      transition: transform 0.5s, opacity 0.5s;
      bottom: 0;
      transform: scale(0);
      transform-origin: center right;
    }
  }
`;
